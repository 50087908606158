// **** Light Theme ****
@media screen and (prefers-color-scheme: light) {
  // Variables
  @import "master/variables-light";
  @import "master/mixins";

  // Core CSS
  @import "master/buttons";
  @import "master/inputs";
  @import "master/typography";
  @import "master/misc";

  // Components
  @import "master/tabs-navs";
  @import "master/navbars";
  @import "master/images";
  @import "master/modal";
  @import "master/carousel";
  @import "master/footers";

  // Cards
  @import "master/cards";
  @import "master/cards/card-plain";
  @import "master/cards/card-register";
  @import "master/cards/card-profile";

  // Pages and sections
  @import "master/examples";
  @import "master/sections";
  @import "master/responsive";

  // React differences
  @import "react/react-differences.scss";

  // React Audio Player
  // @import "react-h5-audio-player/src/styles.scss";
  @import "h5-audio-player.scss";

  // React Image Gallery
  // @import "~react-image-gallery/styles/scss/image-gallery.scss";
  @import "image-gallery.scss";

  // CNWM extensions
  @import "cnwm.scss";
}
// *********************


// **** Dark Theme ****
@media screen and (prefers-color-scheme: dark) {
  // Variables
  @import "master/variables-dark";
  @import "master/mixins";

  // Core CSS
  @import "master/buttons";
  @import "master/inputs";
  @import "master/typography";
  @import "master/misc";

  // Components
  @import "master/tabs-navs";
  @import "master/navbars";
  @import "master/images";
  @import "master/modal";
  @import "master/carousel";
  @import "master/footers";

  // Cards
  @import "master/cards";
  @import "master/cards/card-plain";
  @import "master/cards/card-register";
  @import "master/cards/card-profile";

  // Pages and sections
  @import "master/examples";
  @import "master/sections";
  @import "master/responsive";

  // React differences
  @import "react/react-differences.scss";

  // React Audio Player
  // @import "react-h5-audio-player/src/styles.scss";
  @import "h5-audio-player.scss";

  // React Image Gallery
  // @import "~react-image-gallery/styles/scss/image-gallery.scss";
  @import "image-gallery.scss";

  // CNWM extensions
  @import "cnwm.scss";
}
// *********************
